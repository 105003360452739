import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../request';
import { PostSignedUrlResponse, SuccessResponse } from '~/types/source';
import { UploadManager, decodeFileName } from './util';
import { type DataObject } from '~/types/data-object';
import { addMessageToPage, ExpandedMessage } from '../chat';
import { mergeDeepRight } from 'ramda';
import { SourceStatus } from '~/components/ManageSource/StatusComponent';

interface FileUploadVariables {
  message: ExpandedMessage;
  file: File;
  isNewChat?: boolean;
}

interface Activity {
  total_tasks: number;
  completed: number;
}

const calculateProgress = (activities: Activity[]): { progress: number; status: SourceStatus } => {
  const totalTasks = activities.reduce((acc, curr) => acc + (curr.total_tasks || 1), 0);
  const totalCompleted = activities.reduce((acc, curr) => acc + curr.completed, 0);
  const progress = totalTasks === 0 ? 0 : Math.round((totalCompleted / totalTasks) * 100);
  const status = totalTasks === 0 ? 'In queue' : totalCompleted === totalTasks ? 'Completed' : 'In progress';

  return { progress, status };
};

const generateFileId = (file: File) => `${encodeURIComponent(file.name)}-${file.lastModified}`;
const generateFileName = (dataObjects: DataObject[], fileName: string) => {
  const previousDataObjects = dataObjects.filter((dataObject) => dataObject.name.includes(fileName.split('.')[0]));
  if (previousDataObjects.length >= 1) {
    const fileNumbering = `(${previousDataObjects.length})`;
    return `${fileName.split('.')[0]} ${fileNumbering}.${fileName.split('.')[1]}`;
  }
  return fileName;
};

const encodeSpecialUrlChars = (str: string): string => {
  const specialCharsRegex = /[,%/?:@&=+$#]/g;
  return str.replace(specialCharsRegex, (char) => encodeURIComponent(char));
};

export function useSignedURLMutation() {
  const request = useRequest();
  return useMutation({
    mutationKey: ['signed-url'],
    async mutationFn({ chatId, fileName }: { chatId: string; fileName: string }) {
      const response = await request<PostSignedUrlResponse>(`/api/v2/chat/${chatId}/signed-url`, {
        method: 'POST',
        body: JSON.stringify({
          name: encodeSpecialUrlChars(fileName),
        }),
      });
      if (!response || !('data' in response)) {
        throw new Error(
          'Signed URL Mutation error: No response or response does not contain data getSignedUrlMutation',
        );
      }
      if (!response.success) {
        if (response?.data?.detail?.startsWith('Data object already created')) {
          throw new Error('Signed URL Mutation error: Data object already created');
        }
        throw new Error('Signed URL Mutation error: Invalid response from createSignedUrl');
      }
      if (response.success && !response?.data?.upload_signed_url) {
        throw new Error('Signed URL Mutation error: No upload URL returned');
      }
      if (!response.sourceId) {
        throw new Error('Signed URL Mutation error: No sourceId returned');
      }
      return response as SuccessResponse;
    },
  });
}

function useRefreshDataObjectMutation() {
  const request = useRequest();
  return useMutation({
    mutationKey: ['refresh-data-object'],
    async mutationFn(dataObjectId: string) {
      request(`/api/v2/data_objects/${dataObjectId}/refresh`, {
        method: 'POST',
      });
    },
  });
}

/**
 * 1. POST Call create sources API
    1. Logical bucket with different source types
2. POST Call sources signed url using the new source id
3. PUT with the files you want
    1. data_object_id associated with a file
4. POST to /data_objects/{dataObjectId}
5. GET /data_objects/${dataObjectId}
 * @returns
 */
export function useFileUploadMutation() {
  const request = useRequest();
  const client = useQueryClient();
  const { mutateAsync: requestSignedURL } = useSignedURLMutation();
  const { mutateAsync: refreshDataObject } = useRefreshDataObjectMutation();

  return useMutation({
    mutationKey: ['file-upload'],
    async mutationFn({ file, message, isNewChat }: FileUploadVariables) {
      let fileName = file.name;

      if (!isNewChat) {
        const { data } = await request<{ data: { data_objects: { data: DataObject[] } } }>(
          `/api/v2/chat/${message.chat_id}/sources`,
          {
            method: 'GET',
          },
        );
        fileName = generateFileName(data.data_objects.data ?? [], file.name);
      }

      const response: SuccessResponse = await requestSignedURL({ chatId: message.chat_id, fileName });
      const {
        sourceId,
        data: { upload_signed_url, data_object_id },
      } = response;

      const fileId = generateFileId(file);

      await UploadManager.upload(upload_signed_url, file, {
        message,
        headers: {
          'Content-Type': file.type,
          'x-ms-blob-type': 'BlockBlob',
          'x-ms-meta-source_id': sourceId,
          'x-ms-meta-properties': fileId,
        },
        onProgress(progress) {
          addMessageToPage(
            mergeDeepRight<ExpandedMessage, Partial<ExpandedMessage>>(message, {
              type: 'human',
              chat_id: message.chat_id,
              event: {
                type: 'file_upload',
                content: fileName,
                progress,
                file_id: fileId,
              },
            }),
            client,
          );
        },
      });

      await refreshDataObject(data_object_id);

      const { data } = await request<{ data: DataObject }>(`/api/v2/data_objects/${data_object_id}`, { method: 'GET' });

      let sync = 0;
      while (sync < 100) {
        const isCanceled = UploadManager.isCanceled(fileId);
        if (isCanceled) break;

        const { data } = await request<{ data: DataObject }>(`/api/v2/data_objects/${data_object_id}`, {
          method: 'GET',
        });

        const calculated = calculateProgress(data.activities || []);

        sync = calculated.progress;
        if (sync < 100) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        if (sync === 100) {
          UploadManager.setDone(decodeFileName(fileId));
        }

        addMessageToPage(
          mergeDeepRight<ExpandedMessage, Partial<ExpandedMessage>>(message, {
            type: 'human',
            chat_id: message.chat_id,
            event: {
              type: 'file_upload',
              content: fileName,
              sync,
              progress: 100,
              file_id: fileId,
            },
          }),
          client,
        );
      }

      return data;
    },
  });
}
