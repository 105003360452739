import { useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from '../util';
import { AdditionalQueryOptions, Organization, OrganizationWithRole } from '../types';
import { AssistantWithUserAccessType } from '~/lib/organizations/types/assistant-with-user-access-type';

/**
 * Returns all the organizations for this user
 */
export function useOrganizationsQuery(options: AdditionalQueryOptions<OrganizationWithRole[], ['organizations']> = {}) {
  return useQuery({
    ...options,
    queryKey: ['organizations'],
    async queryFn() {
      return request<OrganizationWithRole[]>({
        pathname: `/api/organizations`,
      });
    },
  });
}

export function useCurrentOrganizationQuery(
  options: AdditionalQueryOptions<Organization | null, ['organization', string]> = {},
) {
  return useQuery({
    ...options,
    queryKey: ['organization', 'current'],
    async queryFn() {
      return request<Organization>({
        pathname: `/api/organizations`,
      });
    },
  });
}

/**
 * Get organization assistants query.
 *
 * This hook fetches the assistants for a given organization and provides query results,
 * along with a manual `refetch` function to refresh the data.
 *
 * @name useOrganizationAssistantsQuery
 * @param id The organization ID.
 * @param options Additional query options.
 * @returns Query result, including a `refetch` function for manual refresh.
 */
export function useOrganizationAssistantsQuery(
  id: number,
  options: AdditionalQueryOptions<AssistantWithUserAccessType[], ['organization', number, 'assistants']> = {},
) {
  const queryClient = useQueryClient(); // Get the query client instance

  const query = useQuery({
    ...options,
    queryKey: ['organization', id, 'assistants'],
    async queryFn() {
      return request<AssistantWithUserAccessType[]>({
        pathname: `/api/organizations/${id}/assistants`,
      });
    },
  });

  const refetch = async () => {
    await queryClient.invalidateQueries({ queryKey: ['organization', id, 'assistants'] });
  };

  return { ...query, refetch };
}
